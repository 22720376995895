<template>
  <b-card title="Vendas por categoria de produto">
    <e-charts
      ref="line"
      size
      :options="option"
      theme="theme-color"
      auto-resize
    />
  </b-card>

</template>

<script>
  import { BCard } from 'bootstrap-vue'
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import theme from './theme.json'

ECharts.registerTheme('theme-color', theme)

export default {
  components: {
    ECharts,
    BCard,
  },
  props: {
    optionData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      option: this.optionData,
    }
  },
}
</script>
